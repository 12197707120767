<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col align-self="center" cols="12">
        <Logo/>
      </v-col>

      <v-col v-if="isLoading" cols="12">
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
        ></v-skeleton-loader>
      </v-col>

      <v-col v-else cols="12">
        <v-card>
          <v-tabs v-model="tab" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <information :account-info="accountInfo"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOAD_ORGANIZATION, REFRESHTOKEN } from "@/store/_actiontypes";
import { ref } from '@vue/composition-api'

import Logo from '@/components/common/Logo.vue'
import Information from '@/components/account-settings/Information.vue'
import { mdiAccountOutline, mdiInformationOutline } from '@mdi/js'


export default {
  components: {
    Logo,
    Information,
  },
  data: () => {
    const tab = ref('');
    const tabs = [
      { title: 'Information', icon: mdiInformationOutline },
    ];
    const attrs = {
      boilerplate: true,
      elevation: 2,
    }

    const accountInfo = {
      name: "",
      type: "individual",
      email: "",
      photo: "",
      ip_address: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      phone: "",
      phoneInt: "",
      country: "",
      region: "",
      city: "",
      postal_code: "",
      address: "",
    }

    return {
      tab,
      tabs,
      attrs: attrs,
      isLoading: true,
      accountInfo,
      icons: {
        mdiAccountOutline,
        mdiInformationOutline,
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      loading: (state) => state.loader.loading,
    }),
  },
  created(){
    if(this.$tools.timeToNextToken() < 300){
      this.REFRESHTOKEN({
        refresh_token: this.user.refresh_token
      }).then((response) => {
        this.handleCheckStatus();
      })
    } else{
      this.handleCheckStatus();
    }
  },
  methods: {
    ...mapActions("account", [LOAD_ORGANIZATION, REFRESHTOKEN]),

    handleCheckStatus(){
      this.getIp();
      this.LOAD_ORGANIZATION().then((res) => {
        this.isLoading = false;
        this.accountInfo.name = res.data.account.account_name;
        this.accountInfo.email = res.data.email;
        this.accountInfo.first_name = res.data.first_name;
        this.accountInfo.last_name = res.data.last_name;

        if(res.data.status === "active"){
          this.$router.push("/overview");
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    getIp(){
      fetch("https://api.ipify.org?format=json").then((res) => res.json()).then(({ ip }) => {
        this.accountInfo.ip_address = ip;
      }).catch((err) => {});
    },
  },
}
</script>
